// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. 2023
// SPDX-License-Identifier: Apache-2.0

import { createClassName, type ComponentProps } from '@iot-prototype-kit/core/utils/element';

import baseStyles from '../styles.module.css';
import styles from './styles.module.css';

export function VideoIcon({ children, className, ...props }: ComponentProps) {
  return (
    <svg className={createClassName(baseStyles.svg, styles.svg, className)} viewBox="0 0 800 800" {...props}>
      <path d="M600 400 300 563.333v-330L600 400Z" />
      <path d="M400 733.333c184.095 0 333.333-149.238 333.333-333.333 0-184.095-149.238-333.333-333.333-333.333C215.905 66.667 66.667 215.905 66.667 400c0 184.095 149.238 333.333 333.333 333.333Z" />
    </svg>
  );
}
