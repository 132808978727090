// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. 2023
// SPDX-License-Identifier: Apache-2.0

import { createClassName, type ComponentProps } from '@iot-prototype-kit/core/utils/element';

import baseStyles from '../styles.module.css';

export function GlobeIcon({ children, className, ...props }: ComponentProps) {
  return (
    <svg className={createClassName(baseStyles.svg, className)} viewBox="0 0 20 20" {...props}>
      <path d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm2.477 11H7.523c.012.26.029.515.05.766l.054.56c.01.091.02.182.032.272l.074.533c.186 1.22.471 2.282.802 3.1l.144.334C9.094 17.471 9.566 18 10 18c.434 0 .906-.529 1.321-1.435l.145-.335c.33-.817.615-1.879.801-3.1l.074-.532.032-.273.054-.559c.021-.25.038-.506.05-.766ZM5.52 11H2.062a8.008 8.008 0 0 0 4.796 6.36c-.362-.767-.659-1.672-.881-2.677l-.11-.534a20.876 20.876 0 0 1-.298-2.295l-.038-.607A24.701 24.701 0 0 1 5.52 11Zm12.418 0H14.48l-.01.246-.039.608c-.06.802-.161 1.571-.298 2.295l-.11.534c-.222 1.005-.519 1.91-.881 2.676A8.008 8.008 0 0 0 17.938 11ZM6.858 2.64l-.086.038A8.008 8.008 0 0 0 2.062 9H5.52l.01-.246.039-.608c.06-.802.161-1.571.298-2.295l.11-.534c.222-1.005.519-1.91.881-2.676ZM10 2h-.013c-.43.01-.897.538-1.308 1.435l-.144.335c-.33.817-.616 1.879-.802 3.1l-.074.532-.032.273-.054.559c-.021.25-.038.506-.05.766h4.954c-.012-.26-.029-.515-.05-.766l-.054-.56c-.01-.091-.02-.182-.032-.272l-.074-.533c-.186-1.22-.471-2.282-.801-3.1l-.145-.334C10.906 2.529 10.434 2 10 2Zm3.142.64.03.065c.349.753.635 1.635.851 2.612l.11.534c.137.724.237 1.493.298 2.295l.038.608.011.246h3.458a8.008 8.008 0 0 0-4.796-6.36Z" />
    </svg>
  );
}
