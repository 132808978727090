// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. 2023
// SPDX-License-Identifier: Apache-2.0

import { createClassName, type ComponentProps } from '@iot-prototype-kit/core/utils/element';

import baseStyles from '../styles.module.css';

export function HeadIcon({ children, className, ...props }: ComponentProps) {
  return (
    <svg className={createClassName(baseStyles.svg, className)} viewBox="0 0 488.62 488.62" {...props}>
      <path d="M298.442 339.4c-15.9 5.2-32.4 7.8-49.2 7.8-42 0-81.5-16.4-111.3-46.1-29.7-29.7-46.1-69.2-46.1-111.3 0-42 16.4-81.6 46.1-111.3 29.8-29.6 69.3-46 111.3-46 20.4 0 40.3 3.9 59.2 11.5 19.5 7.9 37.1 19.6 52.1 34.6 27 27 43.2 62.7 45.7 100.7 1.9 29-4.2 57.7-17.3 83.1l21.5 21.5c17.4-28.3 27.4-61.7 27.4-97.3 0-103.1-83.5-186.6-186.6-186.6-28.5 0-55.6 6.4-79.7 17.9-63.7 29.6-107.9 94.1-107.9 169v31l-38 72.6c-9.6 18.3-.5 33.3 20.2 33.3h17.9v69c0 21.6 17.6 39.2 39.2 39.2l53.8-8 .1 53.8v.4c.3 6.9 6.1 11.5 13.3 10.2l194-34.3c7.3-1.3 13.3-8.4 13.3-15.9V385.1l-55.5-55.5c-7.6 3.9-15.5 7.2-23.5 9.8z" />
      <path d="m459.142 362.5-90.9-90.9-4.9 4.9-5-5c40.1-53.4 35.8-129.5-12.7-178-53.2-53.2-139.5-53.2-192.7 0s-53.2 139.5 0 192.7c48.6 48.6 124.7 52.8 178 12.7l5 5-4.9 4.9 90.9 90.9c10.3 10.3 27 10.3 37.3 0 10.1-10.2 10.1-26.9-.1-37.2zm-135.9-98.6c-40.9 40.9-107.2 40.9-148.1 0-40.9-40.9-40.9-107.2 0-148.1s107.2-40.9 148.1 0c40.9 41 40.9 107.3 0 148.1z" />
      <circle cx="249.242" cy="189.9" r="17.1" />
      <circle cx="302.042" cy="189.9" r="17.1" />
      <circle cx="196.442" cy="189.9" r="17.1" />
    </svg>
  );
}
