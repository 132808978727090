// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. 2023
// SPDX-License-Identifier: Apache-2.0

export const GRAY_COLORS = {
  Black: '#000000',
  Gray1: '#F8F8F9',
  Gray2: '#F4F4F5',
  Gray3: '#F0F0F1',
  Gray4: '#ECECED',
  Gray5: '#E7E8E9',
  Gray6: '#E3E3E5',
  Gray7: '#DFDFE1',
  Gray8: '#DBDBDD',
  Gray9: '#D6D7D9',
  Gray10: '#D2D3D5',
  Gray11: '#CECFD1',
  Gray12: '#CACACD',
  Gray13: '#C6C6C9',
  Gray14: '#C1C2C5',
  Gray15: '#BDBEC1',
  Gray16: '#B9BABD',
  Gray17: '#B5B5BA',
  Gray18: '#B0B1B6',
  Gray19: '#ACADB2',
  Gray20: '#A8A9AE',
  Gray21: '#A4A5AA',
  Gray22: '#A0A1A6',
  Gray23: '#9B9CA2',
  Gray24: '#97989E',
  Gray25: '#93949A',
  Gray26: '#8F9096',
  Gray27: '#8A8C92',
  Gray28: '#86888E',
  Gray29: '#82838A',
  Gray30: '#7e7f86',
  Gray31: '#7A7B82',
  Gray32: '#76777E',
  Gray33: '#72737A',
  Gray34: '#6E6F76',
  Gray35: '#6A6B71',
  Gray36: '#66676D',
  Gray37: '#626369',
  Gray38: '#5E5F65',
  Gray39: '#5A5B61',
  Gray40: '#56575C',
  Gray41: '#525358',
  Gray42: '#4E4F54',
  Gray43: '#4A4B50',
  Gray44: '#46474B',
  Gray45: '#424347',
  Gray46: '#3F3F43',
  Gray47: '#3B3B3F',
  Gray48: '#37373B',
  Gray49: '#333336',
  Gray50: '#2F2F32',
  Gray51: '#2B2B2E',
  Gray52: '#27272A',
  Gray53: '#232325',
  Gray54: '#1F1F21',
  Gray55: '#1B1B1D',
  Gray56: '#171719',
  Gray57: '#131315',
  Gray58: '#0F0F10',
  Gray59: '#0B0B0C',
  Gray60: '#070708',
  White: '#FFFFFF'
};
